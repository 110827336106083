import { z } from 'zod';

export type InitState = {
    state: 'init';
};

export type UnauthenticatedState = {
    state: 'unauthenticated';
    baseUrl: string;
};

export type AuthenticatedState = {
    state: 'authenticated';
    baseUrl: string;
    token: string;
    email: string;
};

export type RefreshingState = {
    state: 'refreshing';
    baseUrl: string;
    token: string;
    email: string;
};

export type ErroredState = {
    state: 'errored';
    baseUrl: string;
    reason: string;
};

export type AuthState =
    | InitState
    | UnauthenticatedState
    | AuthenticatedState
    | ErroredState
    | RefreshingState;

export type AuthDetails = {
    email: string;
    password: string;
    remember?: boolean;
};

const JWTPayload = z.object({
    exp: z.number(),
    iat: z.number(),
    iss: z.string(),
    nbf: z.number(),
    sub: z.string(),
    jti: z.string(),
    prv: z.string(),
    role: z.enum(['admin', 'manager', 'user']),
    client: z.object({
        id: z.number().gt(0),
        name: z.string(),
        created_at: z.string().datetime(),
        updated_at: z.string().datetime(),
        abbreviation: z.string(),
        color: z.string(),
        uuid: z.string().uuid(),
        active: z.boolean(),
        sending_email: z
            .object({
                email: z.string().email(),
            })
            .or(z.null()),
        phone: z
            .object({
                number: z.string(),
                invalidated_at: z.string().or(z.null()),
                invalid_reason: z.string().or(z.null()),
            })
            .or(z.null()),
        master: z.boolean(),
        demo: z.boolean(),
        practice_email: z
            .object({
                email: z.string().email(),
            })
            .or(z.null()),
    }),
});

export type JWTPayload = z.infer<typeof JWTPayload>;

export const initialState: AuthState = {
    state: 'init',
};

export function isState(state: Record<string, unknown>): state is AuthState {
    return [
        'init',
        'started',
        'unauthenticated',
        'authenticated',
        'errored',
        'refreshing',
    ].includes((state as AuthState).state);
}

function canRefresh(
    state: AuthState,
): state is AuthenticatedState | RefreshingState {
    return ['authenticated', 'refreshing'].includes(state.state);
}

export function parseToken(token: string): JWTPayload {
    const [urlEncodedHeader, urlEncodedPayload] = token.split('.');

    JSON.parse(atob(urlEncodedHeader));

    const unverifiedPayload = JSON.parse(atob(urlEncodedPayload));
    return JWTPayload.parse(unverifiedPayload);
}

export function start(state: AuthState, baseUrl: string, now: Date): AuthState {
    if (!canRefresh(state)) {
        return {
            state: 'unauthenticated',
            baseUrl,
        };
    }

    try {
        const { exp } = parseToken(state.token);

        if (exp < Math.ceil(now.getTime() / 1000)) {
            return {
                ...state,
                state: 'refreshing',
            };
        }

        return state;
    } catch (e) {
        return {
            state: 'unauthenticated',
            baseUrl,
        };
    }
}

export async function refresh(state: AuthState): Promise<AuthState> {
    if (!canRefresh(state)) {
        throw new Error(
            `Cannot refresh token when not authenticated. Current State ${state.state}`,
        );
    }

    const { baseUrl, token, email } = state;
    const response: Response = await navigator.locks
        .request('refresh_token', async () => {
            return (await fetch(`${baseUrl}/auth/token/refresh`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })) as Response;
        })
        .catch((e) => {
            if (e instanceof Error) {
                return {
                    state: 'errored',
                    baseUrl,
                    reason: e.message,
                };
            }

            throw e;
        });

    if (!response.ok) {
        return {
            state: 'errored',
            baseUrl,
            reason: `Could not refresh token: ${response.status}`,
        };
    }

    const { token: newToken } = await response.json();

    if (!newToken) {
        return {
            state: 'errored',
            baseUrl,
            reason: 'New token not included in refresh response',
        };
    }

    return {
        state: 'authenticated',
        baseUrl,
        email,
        token: newToken,
    };
}

export async function login(
    state: AuthState,
    details: AuthDetails,
): Promise<AuthState> {
    if (state.state === 'init') {
        throw new Error('Cannot login when auth sub-system is not started');
    }

    const { baseUrl } = state;

    try {
        const token = await authenticate(state.baseUrl, details);

        return {
            state: 'authenticated',
            baseUrl,
            token,
            email: details.email,
        };
    } catch (e) {
        if (e instanceof Error) {
            return {
                state: 'errored',
                baseUrl,
                reason: e.message,
            };
        }

        throw e;
    }
}

export function logout(state: AuthState): UnauthenticatedState {
    if (state.state === 'init') {
        throw new Error('Cannot logout when auth sub-system is not started');
    }

    return {
        state: 'unauthenticated',
        baseUrl: state.baseUrl,
    };
}

async function authenticate(baseUrl: string, details: AuthDetails) {
    const response = await fetch(`${baseUrl}/auth/token`, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(details),
    });

    if (!response.ok) {
        const error: { message: string } = await response.json();
        throw new Error(error.message);
    }

    const auth: { token: string } = await response.json();

    return auth.token;
}

export function started(state: AuthState): boolean {
    return state.state !== 'init';
}

export function authenticated(state: AuthState): state is AuthenticatedState {
    return state.state === 'authenticated';
}

export function assertAuthenticated(
    state: AuthState,
): asserts state is AuthenticatedState {
    if (!authenticated(state)) {
        throw new Error(`State ${state.state} is not AuthenticatedState`);
    }
}

export function errored(state: AuthState): state is ErroredState {
    return state.state === 'errored';
}

export function assertErrored(state: AuthState): asserts state is ErroredState {
    if (!errored(state)) {
        throw new Error(`State ${state.state} is not ErroredState`);
    }
}

export function error(state: ErroredState): string {
    return state.reason;
}

export function token(state: AuthenticatedState): string {
    return state.token;
}

export function email(state: AuthenticatedState): string {
    return state.email;
}
